<template>
	<table class="vesselTooltip">
		<tr>
			<td class="bold" colspan="2" style="text-align: center">{{ vesselnameportcallnumber }}</td>
		</tr>
		<tr>
			<td class="bold">{{ $t('berthplanner.vessel.eta.tooltip') }}:</td>
			<td class="right">{{ getDateFormatted(stop, 'etalocal') }}</td>
		</tr>
		<tr>
			<td class="bold">{{ $t('berthplanner.vessel.etd.tooltip') }}:</td>
			<td class="right">{{ getDateFormatted(stop, 'etdlocal') }}</td>
		</tr>
		<tr>
			<td class="bold">{{ $t('berthplanner.vessel.etc.tooltip') }}:</td>
			<td class="right">{{ getDateFormatted(stop, 'etclocal') }}</td>
		</tr>
		<tr>
			<td class="bold">{{ $t('berthplanner.vessel.ata.tooltip') }}:</td>
			<td class="right">{{ getDateFormatted(stop, 'atalocal') }}</td>
		</tr>
		<tr>
			<td class="bold">{{ $t('berthplanner.vessel.atd.tooltip') }}:</td>
			<td class="right">{{ getDateFormatted(stop, 'atdlocal') }}</td>
		</tr>
		<tr>
			<td class="bold">{{ $t('berthplanner.vessel.atc.tooltip') }}:</td>
			<td class="right">{{ getDateFormatted(stop, 'atclocal') }}</td>
		</tr>
		<tr>
			<td class="bold">{{ $t('berthplanner.vessel.arrivalidletime') }}:</td>
			<td class="right">{{ getArrivalIdleTime(stop) }}</td>
		</tr>
		<tr>
			<td class="bold">{{ $t('berthplanner.vessel.departureidletime') }}:</td>
			<td class="right">{{ getDepartureIdleTime(stop) }}</td>
		</tr>
		<tr>
			<td class="bold">{{ $t('berthplanner.vessel.bollards') }}:</td>
			<td class="right">{{ getBollards(stop) }}</td>
		</tr>
	</table>
</template>

<script>
import DateService from '@/services/date.service.js';

export default {
	props: {
		stop: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			dateTimeFormat: 'dd/MM/yyyy HH:mm',
			timeFormat: 'HH:mm'
		};
	},
	computed: {
		vesselnameportcallnumber() {
			return this.stop.vesselname && this.stop.portcallnumber ? `${this.stop.vesselname} (${this.stop.portcallnumber})` : this.stop.vesselname;
		}
	},
	methods: {
		getDateFormatted(stop, property) {
			return stop[property] ? DateService.format(stop[property], this.dateTimeFormat) : this.$t('berthplanner.vessel.etc.waiting');
		},
		getArrivalIdleTime(stop) {
			return stop.arrivalidletime ? stop.arrivalidletime : this.$t('berthplanner.vessel.idletime.notavailable');
		},
		getDepartureIdleTime(stop) {
			return stop.departureidletime ? stop.departureidletime : this.$t('berthplanner.vessel.idletime.notavailable');
		},
		getBollards(stop) {
			return stop.bollardinicode + ' - ' + stop.bollardendcode;
		}
	}
};
</script>

<style lang="postcss">
.vesselTooltip {
	& .bold {
		font-weight: 600;
	}

	& .right {
		float: right;
	}
}

.v-tooltip__content:has(> .vesselTooltip) {
	/*top: 40vh !important;*/
}
</style>
