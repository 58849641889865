<template>
	<v-layout align-center wrap class="berthplanner-toolbar pa-0">
		<div>
			<v-btn text small @click.native="checkSaveChangesDialog(-1)">
				<v-icon>far fa-chevron-left</v-icon>
			</v-btn>
		</div>
		<v-flex xs10 md2 lg1>
			<v-menu
				v-model="menuDate"
				:close-on-content-click="false"
				:nudge-right="40"
				transition="scale-transition"
				offset-y
				max-width="290px"
				min-width="290px"
				style="z-index: 101"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-text-field
						v-model="selectedStringFormattedDate"
						v-on="on"
						v-bind="attrs"
						prepend-inner-icon="far fa-calendar"
						readonly
						solo
						flat
						hide-details
						class="date-field"
					></v-text-field>
				</template>
				<v-date-picker v-model="selectedStringDate" first-day-of-week="1" no-title @input="onDatePickerInput()"></v-date-picker>
			</v-menu>
		</v-flex>
		<div>
			<v-btn text small @click.native="checkSaveChangesDialog(1)">
				<v-icon>far fa-chevron-right</v-icon>
			</v-btn>
		</div>
		<berth-planner-save-changes-dialog ref="berthPlannerSaveChangesDialog" @response="incrementOrDecrementDay" />
		<v-col xs12 md2 class="berthplanner-toolbar__field mx-0 px-0" v-show="showPortSelect">
			<pui-select
				v-model="currentPort"
				modelName="port"
				itemValue="id"
				itemText="portname"
				:itemsToSelect="[currentPort]"
				return-object
				hide-details
				:placeholder="$t('berthplanner.port')"
				singleItemAutoselect
				@input="countPorts"
				ref="portSelect"
				toplabel
				clearable
				solo
				flat
			></pui-select>
		</v-col>
		<v-col xs12 md2 class="berthplanner-toolbar__field mx-0 px-0" v-if="1 == 2">
			<pui-select
				v-model="currentDock"
				modelName="dock"
				itemValue="id"
				itemText="description"
				:itemsToSelect="[currentDock]"
				:fixedFilter="dockFilter"
				return-object
				hide-details
				:placeholder="$t('berthplanner.dock')"
				toplabel
				clearable
				solo
				flat
			></pui-select>
		</v-col>
		<v-col xs12 md2 class="berthplanner-toolbar__field mx-0 px-0">
			<pui-select
				v-model="currentBerth"
				modelName="berth"
				itemValue="id"
				itemText="name"
				:itemsToSelect="[currentBerth]"
				:fixedFilter="berthFilter"
				return-object
				hide-details
				:placeholder="$t('berthplanner.berth')"
				toplabel
				clearable
				solo
				flat
			></pui-select>
		</v-col>
		<v-col xs12 md2 class="berthplanner-toolbar__field pl-1 pr-0 mr-0">
			<v-text-field
				:placeholder="$t('operationstoolbar.search')"
				v-model="searchText"
				prepend-inner-icon="far fa-search"
				solo
				flat
				outlined
				hide-details
				clearable
			></v-text-field>
		</v-col>
		<v-col xs12 md2>
			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn color="primary" v-bind="attrs" v-on="on">{{ $t('berthplanner.additionalactions') }}</v-btn>
				</template>
				<v-list>
					<v-list-item>
						<v-btn color="primary" text @click.stop="dialog = true">
							{{ $t('berthplanner.statusfilter') }}
						</v-btn>
						<v-dialog v-model="dialog" max-width="500">
							<v-card>
								<v-card-title class="text-h5">{{ $t('berthplanner.statusfilter') }}</v-card-title>
								<v-card-text>
									<v-checkbox
										v-for="(statusItem, index) in statusItems"
										v-model="currentStatusModel"
										:label="statusItem.stopstatusname.charAt(0) + statusItem.stopstatusname.slice(1).toLowerCase()"
										:value="statusItem.stopstatuscode"
										:key="index"
									/>
								</v-card-text>
								<v-divider></v-divider>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn color="primary" text @click="dialog = false">{{ $t('pui9.accept') }}</v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
					</v-list-item>
					<v-list-item>
						<v-btn color="secondary" text @click.stop="$refs.berthPlannerWrongBollardsGrid.visible = true" :disabled="stops.length === 0">
							{{ $t('berthplanner.vesselsoutofrange') }}
						</v-btn>
						<berth-planner-wrong-bollards-dialog ref="berthPlannerWrongBollardsGrid" :stops="stops" />
					</v-list-item>
					<v-list-item v-if="userFunctionalities.has_INSERT_BERTHBLOCK">
						<v-btn color="primary" text @click.stop="$refs.berthPlannerBerthBlockForm.visibleDialog = true">
							{{ $t('berthplanner.berthblock') }}
						</v-btn>
						<berth-planner-berth-block-form
							:currentBerth="currentBerth"
							:berthFilter="berthFilter"
							:userFunctionalities="userFunctionalities"
							ref="berthPlannerBerthBlockForm"
						/>
					</v-list-item>
					<v-list-item>
						<v-btn color="primary" text @click.stop="$refs.berthplannerWeatherinfoDialog.visible = true" :disabled="!validDate()">
							{{ $t('berthplanner.weatherInfo') }}
						</v-btn>
						<berth-planner-weatherinfo-dialog ref="berthplannerWeatherinfoDialog" :currentPort="currentPort" />
					</v-list-item>
					<v-list-item v-if="!readOnly && $store.getters.getSession.functionalities.includes('ACTION_AUTHORIZE_STOP')">
						<v-btn color="primary" text @click.stop="authorizeStops()">
							{{ $t('berthplanner.authorize') }}
						</v-btn>
					</v-list-item>
					<v-list-item v-if="!readOnly && $store.getters.getSession.functionalities.includes('ACTION_PLAN_STOP')">
						<v-btn color="primary" text @click.stop="planStops()">
							{{ $t('berthplanner.plan') }}
						</v-btn>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-col>
		<v-spacer></v-spacer>
		<planner-toolbar-legend legendType="berthplanner" class="pr-4" />
		<authorize-dialog :dialog="dialogAuthorize" :stopsToAuthorize="stopsToAuthorize" @closeAuthorization="dialogAuthorize = false" />
	</v-layout>
</template>

<script>
import BerthPlannerSaveChangesDialog from './BerthPlannerSaveChangesDialog.vue';
import BerthPlannerWrongBollardsDialog from './BerthPlannerWrongBollardsDialog.vue';
import BerthPlannerWeatherinfoDialog from './BerthPlannerWeatherinfoDialog.vue';
import BerthPlannerBerthBlockForm from './BerthPlannerBerthBlockForm.vue';
import PlannerToolbarLegend from '../legend/PlannerToolbarLegend.vue';
import { trackEvent, trackDownload } from '@/plugins/matomo';
import Stopmodals from '@/components/stop/StopModals.vue';
import AuthorizeDialog from '@/components/operations/map/sections/infoperiods/requesteds/AuthorizeDialog.vue';

export default {
	components: {
		BerthPlannerSaveChangesDialog,
		BerthPlannerWrongBollardsDialog,
		BerthPlannerBerthBlockForm,
		BerthPlannerWeatherinfoDialog,
		PlannerToolbarLegend,
		Stopmodals,
		AuthorizeDialog
	},
	props: {
		statusItems: {
			type: Array,
			required: true
		},
		currentStatusItems: {
			type: Array,
			required: true
		},
		readOnly: {
			type: Boolean,
			required: true
		},
		savingEnabled: {
			type: Boolean,
			required: true
		},
		userFunctionalities: {
			type: Object,
			required: true
		},
		vessels: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			selectedDate: null,
			menuDate: false,
			searchText: '',
			currentPort: {},
			currentDock: {},
			currentBerth: {},
			currentStatusModel: this.currentStatusItems,
			stops: [],
			stopsKey: 1,
			dialog: false,
			dialogMeteo: false,
			showPortSelect: window.localStorage.getItem('workingPortsId').split(',').length > 1,
			dialogAuthorize: false,
			stopsToAuthorize: []
		};
	},
	computed: {
		dockFilter() {
			this.userBerthIds = this.$store.getters.getUserBerthIds;
			if (this.currentPort.portid) {
				return {
					groups: [],
					groupOp: 'and',
					rules: []
				};
			} else {
				return {};
			}
		},
		berthFilter() {
			this.userBerthIds = this.$store.getters.getUserBerthIds;
			if (this.currentPort.portid) {
				return {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'id', op: 'in', data: this.userBerthIds }, // berths disponibles para el usuario
						{ field: 'portid', op: 'eq', data: this.currentPort.portid }, // puerto seleccionado
						{ field: 'isanchorage', op: 'ne', data: true } // si es fondeadero no se muestra
					]
				};
			} else {
				return {};
			}
		},
		selectedStringDate: {
			get() {
				if (!this.selectedDate) return null;

				const dd = (this.selectedDate.getDate() < 10 ? '0' : '') + this.selectedDate.getDate();
				const MM = (this.selectedDate.getMonth() + 1 < 10 ? '0' : '') + (this.selectedDate.getMonth() + 1);
				const yyyy = this.selectedDate.getFullYear();
				return `${yyyy}-${MM}-${dd}`;
			},

			set(v) {
				if (v != null) {
					this.selectedDate = new Date(v);
				}
			}
		},
		selectedStringFormattedDate() {
			if (!this.selectedStringDate) return null;

			const [year, month, day] = this.selectedStringDate.split('-');
			return `${day}/${month}/${year}`;
		}
	},
	watch: {
		currentPort() {
			if (this.currentPort == null) {
				this.currentPort = {};
			}
			this.$puiEvents.$emit('berthplanner-toolbar_portSelected', this.currentPort);
		},
		currentBerth() {
			if (this.currentBerth && this.currentBerth.berthcode && this.currentBerth.id) {
				trackEvent(
					'input',
					'dropdowm',
					'berth-planner-container-select-berth',
					'berth: ' + this.currentBerth.berthcode + ' id: ' + this.currentBerth.id
				);
			}
			if (this.currentBerth == null) {
				this.currentBerth = {};
			}
			this.$puiEvents.$emit('berthplanner-toolbar_berthSelected', this.currentBerth);
		},
		selectedDate() {
			this.$puiEvents.$emit('berthplanner-toolbar_dateSelected', this.selectedDate);
		},
		currentStatusItems(v) {
			if (JSON.stringify(this.currentStatusModel) != JSON.stringify(v)) {
				this.currentStatusModel = v;
			}
		},
		currentStatusModel() {
			clearTimeout(this.delayTimer);
			this.delayTimer = setTimeout(() => {
				this.$puiEvents.$emit('berthplanner-toolbar_statusSelected', [...this.currentStatusModel]);
			}, 500);
		},
		searchText() {
			clearTimeout(this.searchDelayTimer);
			this.searchDelayTimer = setTimeout(() => {
				this.$puiEvents.$emit('berthplanner-toolbar_searchText', this.searchText);
			}, 500);
		}
	},
	created() {
		this.$puiEvents.$on('berthplanner-container_wrongBollards', (stops) => {
			this.stops = stops;
			this.stopsKey++;
		});
	},
	mounted() {
		this.selectedDate = new Date();
		this.loadSavedConfiguration();
	},
	methods: {
		loadSavedConfiguration() {
			const configuration = this.$store.getters.getBerthPlannerConfiguration;
			if (configuration.openedVesselDialogStopId) {
				this.selectedDate = configuration.selectedDate || new Date();
				this.currentPort = configuration.currentPort;
				this.currentBerth = configuration.currentBerth;
				this.searchText = configuration.searchText;
			}
		},
		countPorts() {
			const ports = window.localStorage.getItem('workingPortsId').split(',');
			this.showPortsSelectd = ports.length > 1;
		},
		validDate() {
			return (
				(this.isToday(this.selectedDate) || this.selectedDate > new Date()) &&
				this.selectedDate.getUTCDate() < parseInt(new Date().getUTCDate() + 6)
			);
		},
		isToday(date) {
			return date && date.getDay() == new Date().getDay() && date.getMonth() == new Date().getMonth() && date.getYear() == new Date().getYear();
		},
		checkSaveChangesDialog(dayIncrement) {
			if (this.savingEnabled && !this.readOnly) {
				this.$refs.berthPlannerSaveChangesDialog.dayIncrement = dayIncrement;
				this.$refs.berthPlannerSaveChangesDialog.visibleDialog = true;
			} else {
				this.incrementOrDecrementDay(dayIncrement);
			}
		},
		incrementOrDecrementDay(dayIncrement) {
			this.selectedDate = new Date(this.selectedDate.setDate(this.selectedDate.getDate() + dayIncrement));
		},
		onDatePickerInput() {
			this.menuDate = false;
		},
		authorizeStops() {
			let stops = this.vessels.stops.filter(
				(stop) => stop.statusid === 'PA' || stop.statusid === 'PL' || stop.statusid === 'RQ' || stop.statusid === 'AC'
			);

			if (stops.length === 0) {
				this.$puiNotify.error(this.$puiI18n.t('shipsituation.resquesteds.authorization.notSelectedAuthorize'));
				return;
			}
			stops.forEach((element) => {
				element.locationberthcode = element.berthcode;
				element.locationberthid = element.berthid;
				element.inibollard = element.bollardinicode;
				element.endbollard = element.bollardendcode;
				element.berthingtypereq = element.berthingtypecode;
				element.entrydate = element.eta ? element.eta : element.startdatesoli;
				element.destinationdate = element.etd ? element.etd : element.enddatesoli;
				element.berthingtype = element.berthingtypecode;
				element.berthingtypename = element.berthingtypename;
				element.portauthority = this.$store.getters.getPortAuthorityId;
				element.portcode = element.portcode;
				element.selected = 1;
			});

			this.stopsToAuthorize = stops;

			this.dialogAuthorize = true;
			//row.isAction = true;
			//this.$puiEvents.$emit('pui-modalDialogForm-authorizeStops-stop-show', row);
		},
		planStops() {
			let stops = this.vessels.stops.filter((stop) => stop.statusid === 'PA' || stop.statusid === 'RQ' || stop.statusid === 'AC');
			console.log(stops);

			if (stops.length === 0) {
				this.$puiNotify.error(this.$puiI18n.t('shipsituation.planned.plan.notSelectedPlanning'));
				return;
			}
			stops.forEach((element) => {
				element.locationberthcode = element.berthcode;
				element.locationberthid = element.berthid;
				element.bolcodeini = element.bollardinicode;
				element.bolcodeend = element.bollardendcode;
				element.berthingtypereq = element.berthingtypecode;
				element.etaauth = element.eta ? element.eta : element.startdatesoli;
				element.etdauth = element.etd ? element.etd : element.enddatesoli;
				element.berthingtype = element.berthingtypecode;
				element.berthingtypename = element.berthingtypename;
				element.portauthorityid = this.$store.getters.getPortAuthorityId;
				element.portcode = element.portcode;
			});
			let row = stops;

			row.rows = stops;
			console.log(stops);
			row.isAction = true;

			this.$puiEvents.$emit('pui-modalDialogForm-planStop-stop-show', row);
		}
	}
};
</script>

<style lang="postcss">
.berthplanner-toolbar {
	min-height: 65px;

	& .v-input__control {
		min-height: 40px !important;
	}
	&__field {
		margin-right: 24px;
	}
}
</style>
