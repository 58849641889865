import { dia, ui, shapes, elementTools, util, V, g } from '@clientio/rappid';
import JointSettings from '../../JointSettings.js';

import JointItem from '../../items/JointItem.js';
import JointItemConf from '../../items/JointItemConf.js';
import { JointBoardTypes, JointItemTypes } from '../../items/JointTypes.js';
import JointUtils from '../../utils/JointUtils.js';

import JointBollardsAxis from './JointBollardsAxis.js';
import JointDaysAxis from './JointDaysAxis.js';

class JointBollardsBerthBoard {
	constructor(jointMain, days, now, bollards, stops, blocks, bookings) {
		this.jointMain = jointMain;
		this.JointBoardType = JointBoardTypes.BollardsBerth;
		this.jointMain.jointBoard = this;

		this.topAxisHeight = JointSettings.axis.bollardHeight;
		this.leftAxisWidth = JointSettings.axis.dayOfWeekWidth + JointSettings.axis.hourOfDayWidth;

		this.jointTopAxis = new JointBollardsAxis(jointMain, this.topAxisHeight, this.leftAxisWidth, bollards);
		this.jointLeftAxis = new JointDaysAxis(jointMain, this.topAxisHeight, this.leftAxisWidth, days, now);

		this.jointTopAxis.render();
		this.jointLeftAxis.render();

		this.leftBoundary = this.leftAxisWidth;
		this.rightBoundary = this.leftAxisWidth + (bollards.length - 1) * this.jointTopAxis.topAxisItemWidth;
		this.roundedLeftBoundary = Math.round(this.leftBoundary);
		this.roundedRightBoundary = Math.round(this.rightBoundary);

		this.maxVesselWidth = this.roundedRightBoundary - this.roundedLeftBoundary;

		this.renderItems(bollards, stops, blocks, bookings);

		//var link = new shapes.standard.Link();
		//link.source(resource1.rectangle);
		//link.target(resource2.rectangle);
		//link.addTo(this.graph);
		//this.jointMain.paper.scale(0.5, 0.5);
		//this.jointMain.paper.translate(300, 50);
	}

	renderItems(bollards, stops, blocks, bookings) {
		this.boardItems = [];

		// BERTHS
		if (bollards.length > 0) {
			for (const booking of bookings) {
				if (this.jointMain.userFunctionalities.has_READ_BOOKING) {
					let bookingBollardiniX = this.jointTopAxis.getXpositionFromTopItemCode(booking.bollardinicode);
					let bookingBollardendX = this.jointTopAxis.getXpositionFromTopItemCode(booking.bollardendcode);

					// estado aceptado, sin bolardos asignados, ocupa de principio a fin

					if (!bookingBollardiniX) {
						bookingBollardiniX = this.jointTopAxis.getXpositionFromTopItemCode(bollards[0].bolcode);
					}

					if (!bookingBollardendX) {
						bookingBollardendX = this.jointTopAxis.getXpositionFromTopItemCode(bollards[bollards.length - 1].bolcode);
					}

					let itemWidth = bookingBollardendX - bookingBollardiniX;

					let dateini = this.jointLeftAxis.getYpositionFromDate(booking.eta);
					let dateend = this.jointLeftAxis.getYpositionFromDate(booking.etd);
					let itemHeight = dateend - dateini;

					let jsItemConf = new JointItemConf(
						JointItemTypes.Booking,
						JointUtils.getLabelFromBooking(booking, itemHeight),
						bookingBollardiniX,
						dateini,
						itemWidth,
						itemHeight
					);

					let item = new JointItem(jsItemConf, this.jointMain, booking);
					this.boardItems.push(item);
					item.render();

					this.jointMain.jointZManager.setItem(item);
				}
			}

			for (const block of blocks) {
				if (this.jointMain.userFunctionalities.has_LIST_BERTHBLOCK) {
					let blockBollardiniX = this.jointTopAxis.getXpositionFromTopItemCode(block.bollardinicode);
					let blockBollardendX = this.jointTopAxis.getXpositionFromTopItemCode(block.bollardendcode);

					// estado aceptado, sin bolardos asignados, ocupa de principio a fin

					if (!blockBollardiniX) {
						blockBollardiniX = this.jointTopAxis.getXpositionFromTopItemCode(bollards[0].bolcode);
					}

					if (!blockBollardendX) {
						blockBollardendX = this.jointTopAxis.getXpositionFromTopItemCode(bollards[bollards.length - 1].bolcode);
					}

					let itemWidth = blockBollardendX - blockBollardiniX;

					let dateini = this.jointLeftAxis.getYpositionFromDate(block.dateini);
					let dateend = this.jointLeftAxis.getYpositionFromDate(block.dateend);
					let itemHeight = dateend - dateini;

					let jsItemConf = new JointItemConf(
						JointItemTypes.BerthBlock,
						JointUtils.getLabelFromBlock(block, itemHeight),
						blockBollardiniX,
						dateini,
						itemWidth,
						itemHeight
					);

					let item = new JointItem(jsItemConf, this.jointMain, block);
					this.boardItems.push(item);
					item.render();

					this.jointMain.jointZManager.setItem(item);
				}
			}

			for (const stop of stops) {
				if (this.jointMain.userFunctionalities.has_READ_STOP) {
					let stopBollardiniX = this.jointTopAxis.getXpositionFromTopItemCode(stop.bollardinicode);
					let stopBollardendX = this.jointTopAxis.getXpositionFromTopItemCode(stop.bollardendcode);

					// estado aceptado, sin bolardos asignados, ocupa de principio a fin

					if (!stopBollardiniX) {
						stopBollardiniX = this.jointTopAxis.getXpositionFromTopItemCode(bollards[0].bolcode);
					}

					if (!stopBollardendX) {
						stopBollardendX = this.jointTopAxis.getXpositionFromTopItemCode(bollards[bollards.length - 1].bolcode);
					}

					let itemWidth = stopBollardendX - stopBollardiniX;

					let etalocalY = this.jointLeftAxis.getYpositionFromDate(stop.etalocal);
					let etdlocalY = this.jointLeftAxis.getYpositionFromDate(stop.etdlocal);
					let itemHeight = etdlocalY - etalocalY;

					let jsItemConf = new JointItemConf(
						JointItemTypes.Vessel,
						JointUtils.getLabelFromStop(stop, itemHeight),
						stopBollardiniX,
						etalocalY,
						itemWidth,
						itemHeight
					);

					let item = new JointItem(jsItemConf, this.jointMain, stop);
					this.boardItems.push(item);
					item.render();

					this.jointMain.jointZManager.setItem(item);
				}
			}

			setTimeout(() => {
				this.jointMain.jointZManager.axisToFront();
			}, 15);
		}
	}
}

export default JointBollardsBerthBoard;
