import { dia, ui, shapes, elementTools, util, V, g } from '@clientio/rappid';
import JointSettings from '../../JointSettings.js';

import JointItem from '../../items/JointItem.js';
import JointItemConf from '../../items/JointItemConf.js';
import { JointBoardTypes, JointItemTypes } from '../../items/JointTypes.js';
import JointUtils from '../../utils/JointUtils.js';

import JointLimitsAxis from './JointLimitsAxis.js';
import JointDaysAxis from './JointDaysAxis.js';

class JointTerminalsBerthBoard {
	constructor(jointMain, days, now, buoys, stops, blocks, bookings) {
		this.jointMain = jointMain;
		this.JointBoardType = JointBoardTypes.TerminalsBerth;
		this.jointMain.jointBoard = this;

		this.topAxisHeight = JointSettings.axis.bollardHeight;
		this.leftAxisWidth = JointSettings.axis.dayOfWeekWidth + JointSettings.axis.hourOfDayWidth;

		this.jointTopAxis = new JointLimitsAxis(jointMain, this.topAxisHeight, this.leftAxisWidth, buoys);
		this.jointLeftAxis = new JointDaysAxis(jointMain, this.topAxisHeight, this.leftAxisWidth, days, now);

		this.jointTopAxis.render();
		this.jointLeftAxis.render();

		this.leftBoundary = this.leftAxisWidth;
		this.rightBoundary = this.leftAxisWidth + (buoys.length - 1) * this.jointTopAxis.topAxisItemWidth;
		this.roundedLeftBoundary = Math.round(this.leftBoundary);
		this.roundedRightBoundary = Math.round(this.rightBoundary);

		this.maxVesselWidth = this.roundedRightBoundary - this.roundedLeftBoundary;

		this.renderItems(buoys, stops, blocks, bookings);

		//var link = new shapes.standard.Link();
		//link.source(resource1.rectangle);
		//link.target(resource2.rectangle);
		//link.addTo(this.graph);
		//this.jointMain.paper.scale(0.5, 0.5);
		//this.jointMain.paper.translate(300, 50);
	}

	renderItems(buoys, stops, blocks, bookings) {
		this.boardItems = [];

		// BERTHS
		if (buoys.length > 0) {
			for (let n = 0; n < stops.length; n++) {
				var stopBollardiniX = this.jointTopAxis.getXpositionFromTopItemCode(stops[n].bollardinicode);
				var stopBollardendX = this.jointTopAxis.getXpositionFromTopItemCode(stops[n].bollardendcode);

				// estado aceptado, sin bolardos asignados, ocupa de principio a fin

				if (!stopBollardiniX) {
					stopBollardiniX = this.jointTopAxis.getXpositionFromTopItemCode(buoys[0].bolcode);
				}

				if (!stopBollardendX) {
					stopBollardendX = this.jointTopAxis.getXpositionFromTopItemCode(buoys[buoys.length - 1].bolcode);
				}

				var itemWidth = stopBollardendX - stopBollardiniX;

				var etalocalY = this.jointLeftAxis.getYpositionFromDate(stops[n].etalocal);
				var etdlocalY = this.jointLeftAxis.getYpositionFromDate(stops[n].etdlocal);
				var itemHeight = etdlocalY - etalocalY;

				var jsItemConf = new JointItemConf(
					JointItemTypes.Vessel,
					JointUtils.getLabelFromStop(stops[n], itemHeight),
					stopBollardiniX,
					etalocalY,
					itemWidth,
					itemHeight
				);

				var item = new JointItem(jsItemConf, this.jointMain, stops[n]);
				this.boardItems.push(item);
				item.render();
			}

			for (let n = 0; n < blocks.length; n++) {
				var blockBollardiniX = this.jointTopAxis.getXpositionFromTopItemCode(blocks[n].bollardinicode);
				var blockBollardendX = this.jointTopAxis.getXpositionFromTopItemCode(blocks[n].bollardendcode);

				// estado aceptado, sin bolardos asignados, ocupa de principio a fin

				if (!blockBollardiniX) {
					blockBollardiniX = this.jointTopAxis.getXpositionFromTopItemCode(buoys[0].bolcode);
				}

				if (!blockBollardendX) {
					blockBollardendX = this.jointTopAxis.getXpositionFromTopItemCode(buoys[buoys.length - 1].bolcode);
				}

				var itemWidth = blockBollardendX - blockBollardiniX;

				var dateini = this.jointLeftAxis.getYpositionFromDate(blocks[n].dateini);
				var dateend = this.jointLeftAxis.getYpositionFromDate(blocks[n].dateend);
				var itemHeight = dateend - dateini;

				var jsItemConf = new JointItemConf(
					JointItemTypes.BerthBlock,
					JointUtils.getLabelFromBlock(blocks[n], itemHeight),
					blockBollardiniX,
					dateini,
					itemWidth,
					itemHeight
				);

				var item = new JointItem(jsItemConf, this.jointMain, blocks[n]);
				this.boardItems.push(item);
				item.render();
			}

			for (let n = 0; n < bookings.length; n++) {
				var bookingBollardiniX = this.jointTopAxis.getXpositionFromTopItemCode(bookings[n].bollardinicode);
				var bookingBollardendX = this.jointTopAxis.getXpositionFromTopItemCode(bookings[n].bollardendcode);

				// estado aceptado, sin bolardos asignados, ocupa de principio a fin

				if (!bookingBollardiniX) {
					bookingBollardiniX = this.jointTopAxis.getXpositionFromTopItemCode(buoys[0].bolcode);
				}

				if (!bookingBollardendX) {
					bookingBollardendX = this.jointTopAxis.getXpositionFromTopItemCode(buoys[buoys.length - 1].bolcode);
				}

				var itemWidth = bookingBollardendX - bookingBollardiniX;

				var dateini = this.jointLeftAxis.getYpositionFromDate(bookings[n].eta);
				var dateend = this.jointLeftAxis.getYpositionFromDate(bookings[n].etd);
				var itemHeight = dateend - dateini;

				var jsItemConf = new JointItemConf(
					JointItemTypes.Booking,
					JointUtils.getLabelFromBooking(bookings[n], itemHeight),
					bookingBollardiniX,
					dateini,
					itemWidth,
					itemHeight
				);

				var item = new JointItem(jsItemConf, this.jointMain, bookings[n]);
				this.boardItems.push(item);
				item.render();
			}
		}
	}
}

export default JointTerminalsBerthBoard;
