<template>
	<div class="berthplanner-container">
		<berth-planner-toolbar
			:statusItems="statusItems"
			:currentStatusItems="currentStatusItems"
			:readOnly="readOnly"
			:savingEnabled="savingEnabled"
			:userFunctionalities="userFunctionalities"
			:vessels="plannerModel"
		/>
		<d-berth-planner-diagram
			v-if="cartoReady && stopsLoaded && currentBerth"
			v-model="plannerModel"
			ref="dBerthPlannerDiagram"
			:currentBerth="currentBerth"
			:firstWeekDay="firstWeekDay"
			:lastWeekDay="lastWeekDay"
			:dailyForecast="dailyForecast"
			:userFunctionalities="userFunctionalities"
			:readOnly="readOnly"
		/>
		<planner-legend
			legendType="berthplanner"
			:statusItems="statusItems"
			:savingEnabled="savingEnabled"
			:userFunctionalities="userFunctionalities"
		/>
	</div>
</template>

<script>
import BerthPlannerToolbar from './toolbar/BerthPlannerToolbar.vue';
import DBerthPlannerDiagram from './DBerthPlannerDiagram.vue';
import PlannerLegend from './legend/PlannerLegend.vue';
import CartoMixin from '@/mixins/CartoMixin.js';

import FilterService from '@/services/filter.service.js';
import WeatherService from '@/services/weather.service.js';

export default {
	components: { BerthPlannerToolbar, DBerthPlannerDiagram, PlannerLegend },
	mixins: [CartoMixin],
	data() {
		return {
			modelName: 'berthplanner',
			selectedDate: null,
			firstWeekDay: null,
			lastWeekDay: null,
			dailyForecast: [],
			currentPort: null,
			currentBerth: null,
			stopsLoaded: null,
			plannerModel: {
				stops: [],
				blocks: [],
				bookings: []
			},
			statusItems: [],
			currentStatusItems: [],
			savingEnabled: false
		};
	},
	computed: {
		locationIds() {
			if (this.currentBerth) {
				if (this.currentBerth.groupIds) {
					return this.currentBerth.groupIds;
				} else {
					if (this.currentBerth.id) {
						return [this.currentBerth.id];
					}
				}
			}

			return undefined;
		},

		//
		// PERMISOS
		//
		readOnly() {
			return !this.$route.path.includes('dberthplanner');
		},
		has_READ_STOP() {
			return this.$store.getters.hasFunctionality('READ_STOP');
		},
		has_EDIT_STOP() {
			return this.$store.getters.hasFunctionality('EDIT_STOP');
		},
		has_LIST_BERTHBLOCK() {
			return this.$store.getters.hasFunctionality('LIST_BERTHBLOCK');
		},
		has_INSERT_BERTHBLOCK() {
			return this.$store.getters.hasFunctionality('INSERT_BERTHBLOCK');
		},
		has_UPDATE_BERTHBLOCK() {
			return this.$store.getters.hasFunctionality('UPDATE_BERTHBLOCK');
		},
		has_READ_BOOKING() {
			return this.$store.getters.hasFunctionality('READ_BOOKING');
		},
		has_WRITE_BOOKING() {
			return this.$store.getters.hasFunctionality('WRITE_BOOKING');
		},
		has_CREATE_SUBSCRIPTION() {
			return this.$store.getters.hasFunctionality('CREATE_SUBSCRIPTION');
		},
		userFunctionalities() {
			return {
				has_READ_STOP: this.has_READ_STOP,
				has_EDIT_STOP: this.has_EDIT_STOP,
				has_LIST_BERTHBLOCK: this.has_LIST_BERTHBLOCK,
				has_UPDATE_BERTHBLOCK: this.has_UPDATE_BERTHBLOCK,
				has_INSERT_BERTHBLOCK: this.has_INSERT_BERTHBLOCK,
				has_READ_BOOKING: this.has_READ_BOOKING,
				has_WRITE_BOOKING: this.has_WRITE_BOOKING,
				has_CREATE_SUBSCRIPTION: this.has_CREATE_SUBSCRIPTION
			};
		}
	},
	watch: {
		readonly(v) {
			this.getStops();
		},
		stopsLoaded(loaded) {
			this.$store.state.global.puiloader.show = !loaded;
		},
		async currentPort(v) {
			this.dailyForecast = await this.getDailyForecastFor7Days();
		}
	},
	created() {
		this.statusItems = this.getStatusItems();
		this.currentStatusItems = this.getDefaultStatus();
	},
	mounted() {
		this.getWeekInfo(this.selectedDate);
		this.savingEnabled = false;
		this.getStops();

		this.$puiEvents.$on('berthplanner-toolbar_auth', () => {
			console.log('berthplanner-toolbar_auth');
			this.savingEnabled = false;
			this.getStops();
		});

		this.$puiEvents.$on('berthplanner-toolbar_plan', () => {
			this.savingEnabled = false;
			this.getStops();
		});

		this.$puiEvents.$on('berthplanner-toolbar_dateSelected', (dateSelected) => {
			this.$store.state.berthPlanner.configuration.selectedDate = dateSelected;
			this.selectedDate = dateSelected;
			this.savingEnabled = false;
			this.getWeekInfo(dateSelected);
			this.getStops();
		});
		this.$puiEvents.$on('berthplanner-toolbar_portSelected', (portSelected) => {
			this.$store.state.berthPlanner.configuration.currentPort = portSelected;
			this.currentPort = portSelected;
			this.getStops();
		});
		this.$puiEvents.$on('berthplanner-toolbar_berthSelected', (berthSelected) => {
			this.$store.state.berthPlanner.configuration.currentBerth = berthSelected;
			this.currentBerth = berthSelected;
			this.getStops();
		});
		this.$puiEvents.$on('berthplanner-toolbar_statusSelected', (statusSelected) => {
			this.currentStatusItems = statusSelected;
			this.getStops();
		});
		this.$puiEvents.$on('berthplanner-toolbar_searchText', (searchText) => {
			this.$store.state.berthPlanner.configuration.searchText = searchText;
			this.searchText = searchText;
			this.getStops();
		});

		this.$puiEvents.$on('operationscountdown_reload', () => {
			this.getStops();
		});
		this.$puiEvents.$on('berthplanner-vesselDialog_update', (updatedStop) => {
			this.savingEnabled = true;
			this.plannerModel.stops.forEach((stop, index) => {
				if (stop.id == updatedStop.id) {
					updatedStop.etalocal = new Date(updatedStop.eta);
					updatedStop.etdlocal = new Date(updatedStop.etd);
					this.plannerModel.stops[index] = updatedStop;
				}
			});
			this.$refs.dBerthPlannerDiagram.refresh();
		});
		this.$puiEvents.$on('berthplanner-berthBlock_removal', (id) => {
			this.savingEnabled = true;
			this.plannerModel.blocks.forEach((block) => {
				if (block.id == id) {
					block.delete = true;
				}
			});
		});
	},
	destroyed() {
		this.$puiEvents.$off('berthplanner-toolbar_auth');

		this.$puiEvents.$off('berthplanner-toolbar_plan');

		this.$puiEvents.$off('berthplanner-toolbar_dateSelected');
		this.$puiEvents.$off('berthplanner-toolbar_portSelected');
		this.$puiEvents.$off('berthplanner-toolbar_berthSelected');
		this.$puiEvents.$off('berthplanner-toolbar_statusSelected');
		this.$puiEvents.$off('berthplanner-toolbar_searchText');

		this.$puiEvents.$off('berthplanner-operationscountdown_reload');
		this.$puiEvents.$off('berthplanner-vesselDialog_update');
		this.$puiEvents.$off('berthplanner-berthBlock_removal');
	},
	methods: {
		getWeekInfo(date) {
			if (date) {
				this.firstWeekDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
				this.lastWeekDay = new Date(this.firstWeekDay.getFullYear(), this.firstWeekDay.getMonth(), this.firstWeekDay.getDate() + 7);
			}
		},
		getStatusItems() {
			let stopsStatusValues =
				this.$store.getters.stopsStatusValues && this.$store.getters.stopsStatusValues.filter((status) => status.visibleberthingplan);
			stopsStatusValues.sort((a, b) => {
				return a.orderby - b.orderby;
			});
			return stopsStatusValues || [];
		},
		getDefaultStatus() {
			let currentStatusItems = [];
			this.statusItems.forEach((item) => {
				currentStatusItems.push(item.stopstatuscode);
			});
			return currentStatusItems;
		},
		async getDailyForecastFor7Days() {
			if (this.currentPort && this.currentPort.latitude && this.currentPort.longitude) {
				return await WeatherService.getDailyForecastFor7Days(this.currentPort.latitude, this.currentPort.longitude);
			} else {
				return null;
			}
		},
		async getStops() {
			this.stopsLoaded = false;

			this.model = {
				stops: [],
				blocks: [],
				bookings: []
			};

			const params = this.getParams();
			await this.$puiRequests.postRequest(
				'/vstopberthplanner/getList',
				params,
				(response) => {
					try {
						this.model.stops = this.fillStopsFromResponse(response);
					} catch (error) {
						console.error(error);
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);

			const selectedDateMin = this.convertDateToString(this.firstWeekDay);
			const selectedDateMax = this.convertDateToString(this.lastWeekDay);

			if (this.userFunctionalities.has_LIST_BERTHBLOCK) {
				const berthblockfilter = {
					groupOp: 'and',
					groups: [],
					rules: [
						{ field: 'locationid', op: 'in', data: this.locationIds },
						{ field: 'dateini', op: 'ge', data: selectedDateMin },
						{ field: 'dateend', op: 'le', data: selectedDateMax }
					]
				};

				this.model.blocks = [];

				await this.$puiRequests.postRequest(
					'/berthblock/list',
					{
						filter: berthblockfilter,
						rows: -1
					},
					(response) => {
						try {
							if (response.data && response.data.data && response.data.data.length > 0) {
								for (let i = 0, length = response.data.data.length; i < length; i++) {
									this.model.blocks.push(response.data.data[i]);
								}
							}
						} catch (error) {
							console.error(error);
						}
					},
					(error) => {
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					}
				);
			}

			if (this.userFunctionalities.has_READ_BOOKING) {
				const bookingfilter = {
					groupOp: 'and',
					groups: [],
					rules: [
						{ field: 'berth', op: 'in', data: this.locationIds },
						{ field: 'eta', op: 'ge', data: selectedDateMin },
						{ field: 'etd', op: 'le', data: selectedDateMax }
					]
				};

				await this.$puiRequests.postRequest(
					'/booking/list',
					{
						filter: bookingfilter,
						rows: -1
					},
					(response) => {
						try {
							if (response.data && response.data.data && response.data.data.length > 0) {
								this.model.bookings = this.fillBookingsFromResponse(response);
							}
						} catch (error) {
							console.error(error);
						}
					},
					(error) => {
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					}
				);
			}
			this.plannerModel = this.model;
			this.stopsLoaded = true;
		},
		getParams() {
			const firstDate = this.convertDateToString(this.firstWeekDay);
			const lastDate = this.convertDateToString(this.lastWeekDay);
			const portId = this.currentBerth && this.currentBerth.portid ? this.currentBerth.portid : undefined;
			const filter = {
				groupOp: 'and',
				groups: FilterService.getBerthPlannerFilterGroups(firstDate, lastDate, this.locationIds, portId, this.currentStatusItems),
				rules: []
			};
			const params = {
				model: this.modelName,
				page: 1,
				queryLang: this.$store.getters.getUserLanguage,
				rows: 100,
				filter: filter
			};
			if (this.orderingBy) {
				params.order = [{ column: this.orderingBy, direction: 'asc' }];
			}
			if (this.searchText) {
				params.queryText = this.searchText;
				params.queryFields = ['vesselname', 'portcallnumber'];
			}

			return params;
		},
		fillStopsFromResponse(response) {
			let stops = [];

			if (response.data && response.data.data && response.data.data.length > 0) {
				for (let i = 0, length = response.data.data.length; i < length; i++) {
					const stop = response.data.data[i];

					stop.idstop = parseInt(stop.id);

					stop.etalocal = stop.etaplanner ? new Date(stop.etaplanner) : new Date(stop.eta);
					stop.etdlocal = stop.etaplanner ? new Date(stop.etdplanner) : new Date(stop.etd);
					stop.delayed = stop.delayeta || stop.delayetc ? 'DELAYED' : undefined;
					stop.lessthantwentyfourtoetaNegated = !stop.lessthantwentyfourtoeta;
					stop.etalocal = stop.etaterminal ? new Date(stop.etaterminal) : stop.eta ? new Date(stop.eta) : undefined;
					stop.etdlocal = stop.etdterminal ? new Date(stop.etdterminal) : stop.etd ? new Date(stop.etd) : undefined;
					stop.etclocal = stop.etc ? new Date(stop.etc) : undefined;
					stop.rtslocal = stop.rts ? new Date(stop.rts) : undefined;
					stop.atalocal = stop.ataterminal ? new Date(stop.ataterminal) : stop.ata ? new Date(stop.ata) : undefined;
					stop.atdlocal = stop.atdterminal ? new Date(stop.atdterminal) : stop.atd ? new Date(stop.atd) : undefined;
					stop.atclocal = stop.atc ? new Date(stop.atc) : undefined;
					stop.ninetyminstoetc = this.isStopEtcMinusXMinutesLowerThanNow(stop, -90);
					stop.sixtyminstoetc = this.isStopEtcMinusXMinutesLowerThanNow(stop, -60);
					stop.thirtyminstoetc = this.isStopEtcMinusXMinutesLowerThanNow(stop, -30);

					if (stop.ninetyminstoetc || stop.sixtyminstoetc || stop.thirtyminstoetc) {
						console.log('Alert: ETC near to reach', stop.vesselname, stop.ninetyminstoetc, stop.sixtyminstoetc, stop.thirtyminstoetc);
					}

					const status = this.statusItems.filter((status) => status.stopstatuscode == stop.statusid);
					status[0] && (stop.statusColor = status[0].colorhex);
					status[0] && (stop.statusOutlineColor = status[0].colorhexoutline);

					if (this.currentStatusItems.includes(stop.statusid)) {
						stops.push(stop);
					}
				}
			}

			return stops;
		},
		fillBookingsFromResponse(response) {
			let bookings = [];

			if (response.data && response.data.data && response.data.data.length > 0) {
				for (let i = 0, length = response.data.data.length; i < length; i++) {
					const booking = response.data.data[i];

					booking.idstop = parseInt(booking.id);
					booking.stop = parseInt(booking.id);
					booking.bollardini = parseFloat(booking.bolini);
					booking.bollardend = parseFloat(booking.bolend);
					booking.bollardinicode = parseFloat(booking.bolini);
					booking.bollardendcode = parseFloat(booking.bolend);

					booking.vesselname = booking.shipname ? booking.shipname : '';
					booking.vesseltype = booking.shiptypename ? booking.shiptypename : '';

					booking.delayed = undefined;
					booking.etaplannerlocal = new Date(booking.eta);
					booking.etdplannerlocal = new Date(booking.etd);
					booking.etalocal = booking.eta ? new Date(booking.eta) : undefined;
					booking.etdlocal = booking.etd ? new Date(booking.etd) : undefined;
					booking.etclocal = booking.etc ? new Date(booking.etc) : undefined;
					booking.rtslocal = booking.rts ? new Date(booking.rts) : undefined;
					booking.atalocal = booking.ata ? new Date(booking.ata) : undefined;
					booking.atdlocal = booking.atd ? new Date(booking.atd) : undefined;
					booking.atclocal = booking.atc ? new Date(booking.atc) : undefined;

					bookings.push(booking);
				}
			}

			return bookings;
		},
		convertDateToString(date) {
			if (date) {
				const addZero = function (num) {
					return num < 10 ? '0' + num : num;
				};
				return `${date.getFullYear()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}`;
			}
			return null;
		},
		isStopEtcMinusXMinutesLowerThanNow(stop, xMinutes) {
			if (!stop || !stop.etc || stop.status !== 'INITIATED' || stop.atc) {
				return false;
			}
			const now = new Date();
			const etc = new Date(stop.etc);
			if (etc.getTime() < now.getTime()) {
				return false;
			}
			const etcMinusXMinutes = new Date(etc.getTime() + xMinutes * 60000);
			return etcMinusXMinutes.getTime() <= now.getTime();
		},

		// -------------------

		back() {
			this.savingEnabled = false;
			this.getStops();
		},
		async save() {
			await this.plannerModel.stops.forEach((stop) => {
				new Promise(async (resolve) => {
					await this.$puiRequests.patchRequest(
						'/stop/patch?id=' + stop.id,
						{
							bollardiniid: stop.bollardiniid,
							bollardendid: stop.bollardendid,

							berthingtype: stop.berthingtypecode,

							startdate: stop.eta,
							enddate: stop.etd
						},
						() => {
							this.$puiNotify.success(this.$t('pui9.save.success'));
							resolve(true);
						},
						(error) => {
							this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
							resolve(false);
						}
					);
				});
			});

			await this.plannerModel.blocks.forEach((block) => {
				let requestBerthBlock = Object.assign({}, block);
				requestBerthBlock.bollardini = requestBerthBlock.bollardiniid;
				requestBerthBlock.bollardend = requestBerthBlock.bollardendid;

				new Promise(async (resolve) => {
					if (requestBerthBlock.delete != true) {
						await this.$puiRequests.patchRequest(
							'/berthblock/patch?id=' + requestBerthBlock.id,
							requestBerthBlock,
							() => {
								this.$puiNotify.success(this.$t('pui9.save.success'));
							},
							(error) => {
								this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
								resolve(false);
							}
						);
					} else {
						await this.$puiRequests.deleteRequest(
							'/berthblock/delete?id=' + requestBerthBlock.id,
							null,
							() => {
								this.$puiNotify.success(this.$t('pui9.save.success'));
							},
							(error) => {
								this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
								resolve(false);
							}
						);
					}

					resolve(true);
				});
			});
			await this.plannerModel.bookings.forEach((booking) => {
				new Promise(async (resolve) => {
					await this.$puiRequests.patchRequest(
						'/booking/patch?id=' + booking.id,
						{
							bollardiniid: booking.bollardiniid,
							bollardendid: booking.bollardendid,

							eta: booking.eta,
							etd: booking.etd
						},
						() => {
							this.$puiNotify.success(this.$t('pui9.save.success'));
							resolve(true);
						},
						(error) => {
							this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
							resolve(false);
						}
					);
				});
			});

			this.savingEnabled = false;

			// patch stop -> stop allocation -> v_stop_berthplanner ~ 150/200 ms

			setTimeout(() => {
				this.getStops();
				this.$refs.dBerthPlannerDiagram.refresh();
			}, 2000);
		}
	}
};
</script>
