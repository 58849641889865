<template>
	<v-dialog v-model="visibleDialog" scrollable :max-width="500">
		<v-card>
			<v-card-title>
				<span class="headline">{{ $t('berthplanner.berthblock') }}</span>
				<v-spacer />
				<v-btn style="min-width: 32px" small depressed @click="visibleDialog = false">
					<i class="far fa-times"></i>
				</v-btn>
			</v-card-title>
			<v-divider />
			<v-card-text class="pt-3">
				<v-form class="pt-3" ref="form" lazy-validation @submit.prevent>
					<v-row class="pui-form-layout">
						<v-flex xs12>
							<!-- LOCATIONID -->
							<span>{{ $t('stopblock.locationid') }}</span>
							<pui-select
								:id="`locationid-${modelName}`"
								:attach="`locationid-${modelName}`"
								v-model="internalModel"
								modelName="berth"
								itemValue="id"
								itemText="name"
								:modelFormMapping="{ id: 'locationid' }"
								:itemsToSelect="itemsToSelectBerth"
								:fixedFilter="berthFilter"
								return-object
								solo
								flat
								hide-details
								:placeholder="$t('stopblock.locationid')"
								toplabel
								clearable
								:key="refresher"
								@change="getBollardsFromBerth"
							></pui-select>
						</v-flex>
						<v-flex xs12>
							<!-- DATEINI -->
							<pui-date-field
								:id="`dateini-stopblock`"
								v-model="internalModel.dateini"
								:max="internalModel.dateend"
								:label="$t('stopblock.dateini')"
								required
								toplabel
								time
							></pui-date-field>

							<pui-date-field
								:id="`dateend-stopblock`"
								v-model="internalModel.dateend"
								:label="$t('stopblock.dateend')"
								:min="internalModel.dateini"
								required
								toplabel
								time
							></pui-date-field>
						</v-flex>

						<v-flex xs12>
							<v-row dense v-if="enableBollards">
								<v-col>
									<pui-select
										v-model="internalModel"
										ref="bollardini-stopblock"
										id="bollardini-stopblock"
										:label="$t('stopblock.bollardini')"
										modelName="bollard"
										:modelFormMapping="{ id: 'bollardini' }"
										:itemsToSelect="[{ id: internalModel.bollardini }]"
										:rules="bollardRules"
										itemValue="id"
										itemText="bolcode"
										:fixedFilter="berthBollardsFilter"
										:order="{ orderby: 'asc' }"
										toplabel
										reactive
									></pui-select>
								</v-col>
								<v-col>
									<pui-select
										v-model="internalModel"
										ref="bollardend-stopblock"
										id="bollardend-stopblock"
										:label="$t('stopblock.bollardend')"
										modelName="bollard"
										:modelFormMapping="{ id: 'bollardend' }"
										:itemsToSelect="[{ id: internalModel.bollardend }]"
										:rules="bollardRules"
										itemValue="id"
										itemText="bolcode"
										:fixedFilter="berthBollardsFilter"
										:order="{ orderby: 'asc' }"
										toplabel
										reactive
									></pui-select>
								</v-col>
							</v-row>
						</v-flex>
						<v-flex xs12>
							<pui-text-field
								:id="`name-stopblock`"
								v-model="internalModel.name"
								:label="$t('stopblock.name')"
								required
								toplabel
								maxlength="50"
							></pui-text-field>
						</v-flex>
					</v-row>
				</v-form>
			</v-card-text>
			<!-- Page Footer -->
			<v-card-actions>
				<v-icon color="red accent-4" @click="deleteBlock()" v-if="internalModel.idblock">fas fa-trash-alt</v-icon>
				<v-spacer></v-spacer>
				<v-btn text @click="visibleDialog = false">{{ $t('form.cancel') }}</v-btn>
				<v-btn color="primary" text @click="save()">{{ $t('form.save') }}</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		vessel: {
			type: Object,
			default() {
				return {};
			},
			required: false
		},
		currentBerth: {
			default() {
				return {};
			},
			required: false
		},
		berthFilter: {
			type: Object,
			required: true
		},
		userFunctionalities: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			visibleDialog: false,
			modelName: 'berthblock',
			internalModel: this.getEmptyModel(),
			enableBollards: false,
			refresher: 0
		};
	},
	computed: {
		onPuiModelsLoadedAndSaved() {
			return this.$store.state.modelsLoadedAndSaved;
		},
		storedModel: {
			get: function () {
				return this.$store.state.berthPlanner.configuration.currentBerthBlock;
			},
			set: function (newValue) {
				return (this.$store.state.berthPlanner.configuration.currentBerthBlock = newValue);
			}
		},
		itemsToSelectBerth() {
			return [{ id: this.internalModel.locationid }];
		},
		berthBollardsFilter() {
			// bollards pui-select filter
			let locationid = this.internalModel && this.internalModel.locationid ? this.internalModel.locationid : this.currentBerth.id;

			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'berth_id', op: 'eq', data: locationid }]
			};
		},
		bollardRules() {
			return [this.internalModel.bollardiniorderby < this.internalModel.bollardendorderby || this.$t('stop.bollardsException')];
		}
	},
	watch: {
		visibleDialog() {
			this.getBollardsFromBerth();
			this.internalModel.locationid = this.currentBerth.id;
			this.refresher++;
		},
		'currentBerth.id'() {
			this.getBollardsFromBerth();
		},
		'internalModel.bollardini'() {
			this.internalModel.bollardiniorderby = this.$refs['bollardini-stopblock'] && this.$refs['bollardini-stopblock'].selectedItems.orderby;
		},
		'internalModel.bollardend'() {
			this.internalModel.bollardendorderby = this.$refs['bollardend-stopblock'] && this.$refs['bollardend-stopblock'].selectedItems.orderby;
		}
	},
	mounted() {
		this.storedModel ? (this.internalModel = this.storedModel) : (this.internalModel = this.getEmptyModel());
		this.getBollardsFromBerth();
	},
	methods: {
		getEmptyModel() {
			return {
				bollardini: {},
				bollardiniorderby: {},
				bollardend: {},
				bollardendorderby: {},
				locationid: this.currentBerth.id
			};
		},
		fillBlockDataFromVessel(vessel) {
			if (vessel.visitid) {
				this.internalModel.locationid = vessel.berthid;
				this.internalModel.dateini = vessel.etaplanner;
				this.internalModel.dateend = vessel.etdplanner;
				this.internalModel.bollardini = vessel.bollardiniid;
				this.internalModel.bollardend = vessel.bollardendid;
				this.internalModel.idblock = vessel.visitid - 55000;
				this.internalModel.name = vessel.vesselname;
			} else {
				this.internalModel.locationid = this.currentBerth.id;
			}
		},
		getBollardsFromBerth() {
			this.internalModel.bollardini = null;
			this.internalModel.bollardend = null;

			const opts = {
				model: 'berth',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: this.internalModel.locationid
						}
					]
				}
			};

			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data && response.data.data.length > 0 && response.data.data[0].initbol && response.data.data[0].endbol) {
					this.enableBollards = true;
				} else {
					this.enableBollards = false;
				}
			});
		},
		save() {
			if (this.$refs.form.validate()) {
				if (this.internalModel.idblock) {
					this.internalModel.id = this.internalModel.idblock;
					this.$puiRequests.putRequest(
						'/berthblock/update',
						this.internalModel,
						() => {
							this.$puiNotify.success(this.$t('pui9.save.success'));
							this.storedthis.internalModel = null;
						},
						(error) => {
							this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
						}
					);
				} else {
					this.$puiRequests.postRequest(
						'/berthblock/insert',
						this.internalModel,
						() => {
							this.$puiNotify.success(this.$t('pui9.save.success'));
							this.storedModel = null;
						},
						(error) => {
							this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
						}
					);
				}
				this.visibleDialog = false;
				this.$puiEvents.$emit('operationscountdown_reload');
				this.$refs.form.reset();
			}
		},
		deleteBlock() {
			const params = {
				id: this.internalModel.idblock
			};
			this.$puiRequests.deleteRequest(
				'/berthblock/delete',
				this.internalModel.idblock,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				},
				params
			);
			this.visibleDialog = false;
			this.$puiEvents.$emit('operationscountdown_reload');
			this.$refs.form.validate();
		}
	}
};
</script>
